
import GLazyImage from '../g-lazy-image';
import GContainer from '../g-container';
import GHeader from '../g-header';
import GButtonV2 from '../g-button-v2';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'g-infographic',
  components: {
    GLazyImage,
    GContainer,
    GHeader,
    GButtonV2,
  },
  props: {
    infographic: {
      type: Object,
      required: true,
    },
    slug: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    paragraph() {
      if (typeof this.infographic !== 'undefined') {
        return richtext(this.infographic.paragraph);
      }
      return '';
    },
    hasImage() {
      if (typeof this.infographic.image !== 'undefined') {
        return this.infographic.image.imagePublicUrl !== '';
      }
      return false;
    },
    headerTitle() {
      if (typeof this.infographic.header[0].text !== 'undefined') {
        if (
          typeof this.infographic.header[0].text !== 'undefined' &&
          typeof this.infographic.header[0].text !== 'string'
        ) {
          return richtext(this.infographic.header[0].text, true, true);
        } else {
          return this.infographic.header[0].text;
        }
      }
      return undefined;
    },
    headerSubline() {
      if (typeof this.infographic.header[0].subline !== 'undefined') {
        if (
          typeof this.infographic.header[0].subline !== 'undefined' &&
          typeof this.infographic.header[0].subline !== 'string'
        ) {
          return richtext(this.infographic.header[0].subline, true, true);
        } else {
          return this.infographic.header[0].subline;
        }
      }
      return undefined;
    },
  },
  methods: {
    parseLinkHref,
  },
};
