
import GBreadcrumb from '../g-breadcrumb';
import GButtonV2 from '../g-button-v2';
import GContainer from '../g-container';
import modalControl from 'mixins/modalControl';

export default {
  name: 'g-header-media-highlight',
  components: {
    GBreadcrumb,
    iconVectorArrow: () => import('assets/svg/icons/icon-vector-arrow.svg'),
    GButtonV2,
    GContainer,
  },
  mixins: [modalControl],
  props: {
    slug: {
      type: Object,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    videoThumbnail: {
      type: String,
      default: '',
    },
    videoEmbedUrl: {
      type: String,
      default: '',
    },
    primaryButtonText: {
      type: String,
      default: '',
    },
    primaryButtonTarget: {
      type: String,
      required: false,
      default: 'link',
    },
    primaryButtonLink: {
      type: String,
      default: '',
    },
    secondaryButtonText: {
      type: String,
      default: '',
    },
    secondaryButtonTarget: {
      type: String,
      required: false,
      default: 'link',
    },
    secondaryButtonLink: {
      type: String,
      default: '',
    },
    signupCtaId: {
      type: String,
      default: '',
    },
    tagText: {
      type: String,
      default: '',
    },
    tagLink: {
      type: String,
      default: '',
    },
    showBreadcrumb: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getImage() {
      let imageUrl = '';
      if (this.image) {
        imageUrl = this.image;
      }
      if (this.videoThumbnail) {
        imageUrl = this.videoThumbnail;
      }
      return {
        '--background-image': `url(${imageUrl})`,
      };
    },
    iframeSrc() {
      return this.isShown ? this.videoEmbedUrl : '';
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.$nextTick(() => {
        this.$refs['heading']
          .querySelectorAll('.type-highlight-word--yellow')
          .forEach(el => el.classList.add('active'));
      });
    }, 500);
  },
};
