
import GButtonStore from '../g-button-store';
import GDropdown from '../g-dropdown';
import GDropdownItem from '../g-dropdown/g-dropdown-item';
import GSocialLinks from '../g-social-links';
import { setCookie } from '~/utils/cookieHelpers';

export default {
  name: 'g-footer',
  components: {
    logoDeputy: () => import('assets/svg/icons/logo-deputy-footer.svg'),
    GButtonStore,
    GDropdown,
    GDropdownItem,
    GGlobe: () => import('assets/svg/icons/icon-globe.svg'),
    GPhone: () => import('assets/svg/icons/icon-phone.svg'),
    GDropdownIcon: () => import('assets/svg/icons/icon-dropdown.svg'),
    GSocialLinks,
  },
  props: {
    contents: {
      type: Array,
      default: null,
    },
    logoHomeUrl: {
      type: String,
      required: true,
      default: '/',
    },
    region: {
      type: String,
      default: 'us',
    },
    twitterUrl: {
      type: String,
      required: true,
    },
    youtubeUrl: {
      type: String,
      required: true,
    },
    facebookUrl: {
      type: String,
      required: true,
    },
    linkedinUrl: {
      type: String,
      required: true,
    },
    instagramUrl: {
      type: String,
      required: true,
    },
    footerClass: {
      type: String,
      required: true,
      default: 'default-footer',
    },
    legalAndPrivacyLink: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    const dropDownData = {
      au: { value: '/au', text: 'Australia' },
      us: { value: '', text: 'USA/Global' },
      gb: { value: '/gb', text: 'United Kingdom' },
    };
    return {
      showCookiePreferences: true,
      currentYear: new Date().getFullYear(),
      isNumber: false,
      selectedItem: dropDownData[this.region].text,
      selectedValue: '',
      dropDownItems: dropDownData,
      url: '',
    };
  },
  computed: {
    getFooterClass() {
      return {
        'g-footer__above-line-simple-footer':
          this.footerClass === 'footer-minimal',
        'g-footer__above-line': this.defaultFooter,
      };
    },
    noFooter() {
      return { 'g-footer-none': this.footerClass === 'footer-none' };
    },
    defaultFooter() {
      return (
        this.footerClass === 'default-footer' ||
        this.footerClass === 'footer-no-signup'
      );
    },
    showDoNotSellConsent() {
      if (typeof airgap === 'undefined') {
        return false;
      }

      const regimeShowList = [
        'us-do-not-sell',
        'CDPA',
        'CPRA',
        'CPA',
        'NEVADA_SB220',
      ];
      const currentRegimes = airgap.getRegimes();
      return currentRegimes.some(regime => regimeShowList.includes(regime));
    },
  },
  mounted() {
    this.checkIfNumber();
    this.getUrl();
  },
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    checkIfNumber(subheader) {
      if (/^[0-9 +-]+$/.test(subheader)) {
        return true;
      } else {
        return false;
      }
    },
    updateUrl(selectedText) {
      // 8005 here is just to make is work locally
      const regex = /(.com|8005)(\/au|\/gb|\/$)?/;
      const newUrl = window.location.href.replace(
        regex,
        `$1${selectedText.value}`
      );
      const forceRegion = selectedText.value
        ? selectedText.value.replace('/', '')
        : 'us';
      // we set a cookie that we will read from cloudflare to avoid redirecting the user to ip based region
      setCookie('geo', forceRegion, 0.25).then(() => {
        let url;
        try {
          const nUrl = new URL(newUrl);
          nUrl.searchParams.set('geo', forceRegion);
          url = nUrl.href;
        } catch {
          url = newUrl;
        }
        location.assign(url);
      });
    },
    getUrl() {
      if (this.region === 'us') {
        this.url = window.location.origin;
      } else {
        this.url = window.location.origin + `/${this.region}`;
      }
    },
    showCookieConsentModal(viewState) {
      if (typeof transcend === 'undefined') {
        return;
      }
      transcend.showConsentManager({ viewState });
    },
  },
};
